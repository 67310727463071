import { default as index0JacMYqHi4Meta } from "/srv/app/src/pages/🫖/index.vue?macro=true";
import { default as createxLN32QeDvWMeta } from "/srv/app/src/pages/account/create.vue?macro=true";
import { default as _91username_93WAPVBkM48EMeta } from "/srv/app/src/pages/account/edit/[username].vue?macro=true";
import { default as indexe0lsBYZFxeMeta } from "/srv/app/src/pages/account/index.vue?macro=true";
import { default as index0Z1TZOOYkIMeta } from "/srv/app/src/pages/account/password/reset/index.vue?macro=true";
import { default as request9rvzDrVuvuMeta } from "/srv/app/src/pages/account/password/reset/request.vue?macro=true";
import { default as verifyKFGKkHdUD7Meta } from "/srv/app/src/pages/account/verify.vue?macro=true";
import { default as _91username_93FwdjAEXmXiMeta } from "/srv/app/src/pages/account/view/[username].vue?macro=true";
import { default as indexpq9lZ3EdJeMeta } from "/srv/app/src/pages/attendance/index.vue?macro=true";
import { default as index7AunNoToHfMeta } from "/srv/app/src/pages/contact/index.vue?macro=true";
import { default as indexOa8KTi2lEzMeta } from "/srv/app/src/pages/dashboard/index.vue?macro=true";
import { default as appwDvDkkuLZWMeta } from "/srv/app/src/pages/docs/app.vue?macro=true";
import { default as create0fsVo2Wi6AMeta } from "/srv/app/src/pages/event/create.vue?macro=true";
import { default as _91event_name_9367NQLj6G6gMeta } from "/srv/app/src/pages/event/edit/[username]/[event_name].vue?macro=true";
import { default as indexokJeUisFmcMeta } from "/srv/app/src/pages/event/index.vue?macro=true";
import { default as attendanceY3MbybpIfJMeta } from "/srv/app/src/pages/event/view/[username]/[event_name]/attendance.vue?macro=true";
import { default as indexTGXXpuP1kUMeta } from "/srv/app/src/pages/event/view/[username]/[event_name]/index.vue?macro=true";
import { default as invitationp45PPJuphdMeta } from "/srv/app/src/pages/event/view/[username]/[event_name]/invitation.vue?macro=true";
import { default as indexXqH0k07FEEMeta } from "/srv/app/src/pages/event/view/[username]/index.vue?macro=true";
import { default as index8di6wRXAniMeta } from "/srv/app/src/pages/index.vue?macro=true";
import { default as indexzCgNzWAMCiMeta } from "/srv/app/src/pages/invitation/index.vue?macro=true";
import { default as unlockFvYoqyZwvHMeta } from "/srv/app/src/pages/invitation/unlock.vue?macro=true";
import { default as indexlVC5z6L2EHMeta } from "/srv/app/src/pages/legal-notice/index.vue?macro=true";
import { default as indexeyB90ylYahMeta } from "/srv/app/src/pages/notification/index.vue?macro=true";
import { default as indexL0N5TgGWwhMeta } from "/srv/app/src/pages/privacy-policy/index.vue?macro=true";
import { default as indexgUFm4bMeL3Meta } from "/srv/app/src/pages/search/index.vue?macro=true";
import { default as createFrIb6aqferMeta } from "/srv/app/src/pages/session/create.vue?macro=true";
import { default as color_45schemeRlbOxdPD6jMeta } from "/srv/app/src/pages/session/edit/[id]/color-scheme.vue?macro=true";
import { default as indexJDVP2OlSzHMeta } from "/srv/app/src/pages/session/edit/[id]/index.vue?macro=true";
import { default as languageL62YCvFZfGMeta } from "/srv/app/src/pages/session/edit/[id]/language.vue?macro=true";
import { default as _91id_93ouXQ8bRyuYMeta } from "/srv/app/src/pages/session/view/[id].vue?macro=true";
import { default as indexfIKWMbF6B5Meta } from "/srv/app/src/pages/upload/index.vue?macro=true";
import { default as component_45stubeFCxX0stDfMeta } from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubeFCxX0stDf } from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "🫖___de",
    path: "/de/%F0%9F%AB%96",
    component: () => import("/srv/app/src/pages/🫖/index.vue")
  },
  {
    name: "🫖___en",
    path: "/%F0%9F%AB%96",
    component: () => import("/srv/app/src/pages/🫖/index.vue")
  },
  {
    name: "account-create___de",
    path: "/de/account/create",
    component: () => import("/srv/app/src/pages/account/create.vue")
  },
  {
    name: "account-create___en",
    path: "/account/create",
    component: () => import("/srv/app/src/pages/account/create.vue")
  },
  {
    name: "account-edit-username___de",
    path: "/de/account/edit/:username()",
    meta: _91username_93WAPVBkM48EMeta || {},
    component: () => import("/srv/app/src/pages/account/edit/[username].vue")
  },
  {
    name: "account-edit-username___en",
    path: "/account/edit/:username()",
    meta: _91username_93WAPVBkM48EMeta || {},
    component: () => import("/srv/app/src/pages/account/edit/[username].vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    component: () => import("/srv/app/src/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/account",
    component: () => import("/srv/app/src/pages/account/index.vue")
  },
  {
    name: "account-password-reset___de",
    path: "/de/account/password/reset",
    component: () => import("/srv/app/src/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset___en",
    path: "/account/password/reset",
    component: () => import("/srv/app/src/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset-request___de",
    path: "/de/account/password/reset/request",
    component: () => import("/srv/app/src/pages/account/password/reset/request.vue")
  },
  {
    name: "account-password-reset-request___en",
    path: "/account/password/reset/request",
    component: () => import("/srv/app/src/pages/account/password/reset/request.vue")
  },
  {
    name: "account-verify___de",
    path: "/de/account/verify",
    component: () => import("/srv/app/src/pages/account/verify.vue")
  },
  {
    name: "account-verify___en",
    path: "/account/verify",
    component: () => import("/srv/app/src/pages/account/verify.vue")
  },
  {
    name: "account-view-username___de",
    path: "/de/account/view/:username()",
    meta: _91username_93FwdjAEXmXiMeta || {},
    component: () => import("/srv/app/src/pages/account/view/[username].vue")
  },
  {
    name: "account-view-username___en",
    path: "/account/view/:username()",
    meta: _91username_93FwdjAEXmXiMeta || {},
    component: () => import("/srv/app/src/pages/account/view/[username].vue")
  },
  {
    name: "attendance___de",
    path: "/de/attendance",
    component: () => import("/srv/app/src/pages/attendance/index.vue")
  },
  {
    name: "attendance___en",
    path: "/attendance",
    component: () => import("/srv/app/src/pages/attendance/index.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/srv/app/src/pages/contact/index.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/srv/app/src/pages/contact/index.vue")
  },
  {
    name: "dashboard___de",
    path: "/de/dashboard",
    component: () => import("/srv/app/src/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    component: () => import("/srv/app/src/pages/dashboard/index.vue")
  },
  {
    name: "docs-app___de",
    path: "/de/docs/app",
    component: () => import("/srv/app/src/pages/docs/app.vue")
  },
  {
    name: "docs-app___en",
    path: "/docs/app",
    component: () => import("/srv/app/src/pages/docs/app.vue")
  },
  {
    name: "event-create___de",
    path: "/de/event/create",
    component: () => import("/srv/app/src/pages/event/create.vue")
  },
  {
    name: "event-create___en",
    path: "/event/create",
    component: () => import("/srv/app/src/pages/event/create.vue")
  },
  {
    name: "event-edit-username-event_name___de",
    path: "/de/event/edit/:username()/:event_name()",
    meta: _91event_name_9367NQLj6G6gMeta || {},
    component: () => import("/srv/app/src/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event-edit-username-event_name___en",
    path: "/event/edit/:username()/:event_name()",
    meta: _91event_name_9367NQLj6G6gMeta || {},
    component: () => import("/srv/app/src/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event___de",
    path: "/de/event",
    component: () => import("/srv/app/src/pages/event/index.vue")
  },
  {
    name: "event___en",
    path: "/event",
    component: () => import("/srv/app/src/pages/event/index.vue")
  },
  {
    name: "event-view-username-event_name-attendance___de",
    path: "/de/event/view/:username()/:event_name()/attendance",
    meta: attendanceY3MbybpIfJMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: "event-view-username-event_name-attendance___en",
    path: "/event/view/:username()/:event_name()/attendance",
    meta: attendanceY3MbybpIfJMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: "event-view-username-event_name___de",
    path: "/de/event/view/:username()/:event_name()",
    meta: indexTGXXpuP1kUMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username-event_name___en",
    path: "/event/view/:username()/:event_name()",
    meta: indexTGXXpuP1kUMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username-event_name-invitation___de",
    path: "/de/event/view/:username()/:event_name()/invitation",
    meta: invitationp45PPJuphdMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/invitation.vue")
  },
  {
    name: "event-view-username-event_name-invitation___en",
    path: "/event/view/:username()/:event_name()/invitation",
    meta: invitationp45PPJuphdMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/[event_name]/invitation.vue")
  },
  {
    name: "event-view-username___de",
    path: "/de/event/view/:username()",
    meta: indexXqH0k07FEEMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/index.vue")
  },
  {
    name: "event-view-username___en",
    path: "/event/view/:username()",
    meta: indexXqH0k07FEEMeta || {},
    component: () => import("/srv/app/src/pages/event/view/[username]/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/srv/app/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/srv/app/src/pages/index.vue")
  },
  {
    name: "invitation___de",
    path: "/de/invitation",
    component: () => import("/srv/app/src/pages/invitation/index.vue")
  },
  {
    name: "invitation___en",
    path: "/invitation",
    component: () => import("/srv/app/src/pages/invitation/index.vue")
  },
  {
    name: "invitation-unlock___de",
    path: "/de/invitation/unlock",
    meta: unlockFvYoqyZwvHMeta || {},
    component: () => import("/srv/app/src/pages/invitation/unlock.vue")
  },
  {
    name: "invitation-unlock___en",
    path: "/invitation/unlock",
    meta: unlockFvYoqyZwvHMeta || {},
    component: () => import("/srv/app/src/pages/invitation/unlock.vue")
  },
  {
    name: "legal-notice___de",
    path: "/de/legal-notice",
    component: () => import("/srv/app/src/pages/legal-notice/index.vue")
  },
  {
    name: "legal-notice___en",
    path: "/legal-notice",
    component: () => import("/srv/app/src/pages/legal-notice/index.vue")
  },
  {
    name: "notification___de",
    path: "/de/notification",
    component: () => import("/srv/app/src/pages/notification/index.vue")
  },
  {
    name: "notification___en",
    path: "/notification",
    component: () => import("/srv/app/src/pages/notification/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/srv/app/src/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/srv/app/src/pages/privacy-policy/index.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/srv/app/src/pages/search/index.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/srv/app/src/pages/search/index.vue")
  },
  {
    name: "session-create___de",
    path: "/de/session/create",
    component: () => import("/srv/app/src/pages/session/create.vue")
  },
  {
    name: "session-create___en",
    path: "/session/create",
    component: () => import("/srv/app/src/pages/session/create.vue")
  },
  {
    name: "session-edit-id-color-scheme___de",
    path: "/de/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "session-edit-id-color-scheme___en",
    path: "/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "session-edit-id___de",
    path: "/de/session/edit/:id()",
    component: () => import("/srv/app/src/pages/session/edit/[id]/index.vue")
  },
  {
    name: "session-edit-id___en",
    path: "/session/edit/:id()",
    component: () => import("/srv/app/src/pages/session/edit/[id]/index.vue")
  },
  {
    name: "session-edit-id-language___de",
    path: "/de/session/edit/:id()/language",
    component: () => import("/srv/app/src/pages/session/edit/[id]/language.vue")
  },
  {
    name: "session-edit-id-language___en",
    path: "/session/edit/:id()/language",
    component: () => import("/srv/app/src/pages/session/edit/[id]/language.vue")
  },
  {
    name: "session-view-id___de",
    path: "/de/session/view/:id()",
    component: () => import("/srv/app/src/pages/session/view/[id].vue")
  },
  {
    name: "session-view-id___en",
    path: "/session/view/:id()",
    component: () => import("/srv/app/src/pages/session/view/[id].vue")
  },
  {
    name: "upload___de",
    path: "/de/upload",
    component: () => import("/srv/app/src/pages/upload/index.vue")
  },
  {
    name: "upload___en",
    path: "/upload",
    component: () => import("/srv/app/src/pages/upload/index.vue")
  },
  {
    name: component_45stubeFCxX0stDfMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubeFCxX0stDf
  },
  {
    name: component_45stubeFCxX0stDfMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubeFCxX0stDf
  },
  {
    name: component_45stubeFCxX0stDfMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubeFCxX0stDf
  },
  {
    name: component_45stubeFCxX0stDfMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubeFCxX0stDf
  }
]