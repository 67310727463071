import revive_payload_client_h0sPhDcQWM from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wyIHUbIKrQ from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xw7XhFRbMe from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Um7oxmTfYq from "/srv/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.3_dqyagnejw2sanrtcxzp6q2ssye/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_BGwfBM1RCm from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Kce50xJ9eH from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_x2c9VJm6uC from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_S4v78O5ZKz from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pgjfCiK923 from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_325KN89onR from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__jyrs733kpgknepjdnzptucpieu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Qb1VmuA9Oh from "/srv/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/srv/app/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_uxLcHO5Yg1 from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34._qljlepwpt5vvdw35gwaot37njm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import titles_iJ9BzzHciH from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5__2udupqrfk4wdyeuh325rocguvq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_RPB7ZJMLqr from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5__2udupqrfk4wdyeuh325rocguvq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_c7shRieM2K from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@_drfvccpxvagsj575bwicti5baa/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_plod3EOv1V from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@_drfvccpxvagsj575bwicti5baa/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import i18n_R3tmyzlLiP from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_s8xtyUqFpi from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_qsv66ynjbufbzwkvbpqpaub6ne/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_piNfqMK6RH from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_qsv66ynjbufbzwkvbpqpaub6ne/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_pSmSI9oR40 from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_qsv66ynjbufbzwkvbpqpaub6ne/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_nK6tgDJIlt from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_mtT1xGyhlG from "/srv/app/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vu_mjjc3ezkbyzgxypcdqnt542uwa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_InC36YfEb6 from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.14_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3_webpack@5.95.0_esbuild@0.23.1_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import dayjs_pGiXRjcsJO from "/srv/app/src/plugins/dayjs.ts";
import i18n_VfGcjrvSkj from "/srv/app/src/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/srv/app/src/plugins/sentry.client.ts";
import urql_FlK1jaV38U from "/srv/app/src/plugins/urql.ts";
export default [
  revive_payload_client_h0sPhDcQWM,
  unhead_wyIHUbIKrQ,
  router_xw7XhFRbMe,
  _0_siteConfig_Um7oxmTfYq,
  payload_client_BGwfBM1RCm,
  navigation_repaint_client_Kce50xJ9eH,
  check_outdated_build_client_x2c9VJm6uC,
  chunk_reload_client_S4v78O5ZKz,
  plugin_vue3_pgjfCiK923,
  components_plugin_KR1HBZs4kY,
  prefetch_client_325KN89onR,
  plugin_client_Qb1VmuA9Oh,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_uxLcHO5Yg1,
  titles_iJ9BzzHciH,
  defaultsWaitI18n_RPB7ZJMLqr,
  siteConfig_c7shRieM2K,
  inferSeoMetaPlugin_plod3EOv1V,
  i18n_R3tmyzlLiP,
  slideovers_s8xtyUqFpi,
  modals_piNfqMK6RH,
  colors_pSmSI9oR40,
  plugin_client_nK6tgDJIlt,
  plugin_mtT1xGyhlG,
  plugin_InC36YfEb6,
  dayjs_pGiXRjcsJO,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  urql_FlK1jaV38U
]